import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [0,3];

export const dictionary = {
		"/": [~4],
		"/auth/login": [5],
		"/auth/logout": [6],
		"/auth/register": [7],
		"/auth/reset": [8],
		"/features": [9],
		"/my/brands": [~10],
		"/my/brands/[id]": [~11],
		"/my/dashboard": [~12],
		"/my/deals": [~13],
		"/my/deals/[id]": [~14],
		"/my/settings": [~15,[3]],
		"/my/settings/account": [~16,[3]],
		"/my/settings/profile": [~17,[3]],
		"/my/settings/security": [~18,[3]],
		"/my/simple/dashboard": [~19],
		"/my/simple/deals": [~20],
		"/my/simple/deals/[id]": [~21],
		"/my/videos": [~22],
		"/my/videos/[id]": [~23],
		"/pricing": [24],
		"/releases": [25]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';